import React from "react"
import { graphql } from "gatsby"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  return (
    <div className="blog-post-container">
      <div className="blog-post">
        <h1 className="text-xl text-gray-400 font-thin text-center">REPHORM PRESENTS</h1>
        <h1 className="text-7xl text-cyan-400 font-bold text-center">SIMON HAIGH</h1>
        <h1 className="text-2xl text-gray-500 font-regular text-center">Freelance Experience and Graphic Designer</h1>
        <h1 className="text-m text-black font-medium text-center">si@rephorm.com.au</h1>
        <a className="text-m text-cyan-500 font-medium" href="https://www.linkedin.com/in/sihaigh/">LinkedIn profile</a>
      </div>
    </div>
  )
}

//<h1 className="text-3xl text-blue-500 font-bold">{frontmatter.title}</h1>
//<h1 className="text-3xl text-blue-500 font-bold">{frontmatter.date}</h1>
//<div className="blog-post-content" dangerouslySetInnerHTML={{ __html: html }}/>
//        <center><a className="text-m text-cyan-500 font-medium" href="https://www.linkedin.com/in/sihaigh/">LinkedIn profile</a></center>

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`